import React, { Component } from 'react';

import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './Components/HomePage'
import LandingPage from './Components/LandingPage';
import AboutPage from './Components/AboutPage';
import BlogPage from './Components/BlogPage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/*All routing goes here. Each page should have its own react component*/}
          <Route path="/" element={<LandingPage />} />
          <Route path="/amazon" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/blog" element={<BlogPage />} />
        </Routes>
      </BrowserRouter>
      
      
    </div>
  );
}

export default App;
