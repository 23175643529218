import React, { Component } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';

class Navigation extends Component {
    render() {
        return (
            <Navbar className="Navigation" expand="lg" variant="light" bg="light" sticky="top">
                <Container className="px-4 px-lg-5">
                    <Navbar.Brand href="/">CTO | Catch The Offer</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className="me-auto">
                            {/*Page links go here*/}
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/blog">Blog</Nav.Link>
                            <Nav.Link href="/about">About</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    
                </Container>
            </Navbar>
            
)       
    }
}
export default Navigation;
