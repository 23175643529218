import React, { Component } from 'react';

import Navigation from './Navigation';
import Footer from './Footer';

class AboutPage extends Component {
  render() {
      return (
          <div className="AboutPage">
            <Navigation />
            
            <Footer/>
          </div>
      )
  }
}

export default AboutPage;
