import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
          <footer class="Footer py-5 bg-dark">
              <div class="container"><p class="m-0 text-center text-white">Copyright &copy; Your Website 2022</p></div>
          </footer>
)
    }
}
export default Footer;
