import React, { Component } from 'react';
import { Card, Button } from 'react-bootstrap';

class ProductCard extends Component {
    state = {
        productName: "Example Product",
        productPrice: 10,
        productImage: "https://dummyimage.com/450x300/dee2e6/6c757d.jpg",
        productLink: "https://www.amazon.co.uk/"
    }

    constructor(props) {
        super(props)
        this.state = {
            productName: this.props.productName,
            productPrice: this.props.productPrice,
            productImage: this.props.productImage,
            productLink: this.props.productLink
        }
    }

    render(){
        return (
            <Card className="ProductCard h-100">
                <Card.Img variant="top" src={this.state.productImage} />
                <Card.Body className="p-4">
                    <Card.Title>{this.state.productName}</Card.Title>
                    {/* .toFixed(2) rounds the price to 2 decimal places */}
                    <Card.Subtitle>£{this.state.productPrice.toFixed(2)}</Card.Subtitle>
                </Card.Body>
                <Card.Footer>
                    <Button href={this.state.productLink} target="_blank" variant="primary">Shop Now</Button>
                </Card.Footer>
            </Card>
        )
    }
}

export default ProductCard;