import React, { Component } from 'react';

import { Button, ButtonGroup, Row, Col, Figure } from 'react-bootstrap'

import Navigation from './Navigation';
import Footer from './Footer';

import banner from '../Images/green-chameleon-s9CC2SKySJM-unsplash.jpg'
import ipad from '../Images/ipad-2021-hero-space-wifi-select.png'
import matein from '../Images/matein.jpg'
import sandisk from '../Images/sandisk.jpg'

class BlogPage extends Component {
  render() {
      return (
          <div className="BlogPage">
            <Navigation />
            <div class="container mt-5">
                <div class="row">
                    <div class="col">
                        <article>
                            <header class="mb-4">
                                <h1 class="fw-bolder mb-1">Top Deals for Students!</h1>
                            </header>
                            <figure class="mb-4"><img class="img-fluid rounded" src={banner} alt="..." /></figure>
                            <section class="mb-5">
                                <p class="fs-5 mb-4">Since I’m starting university this September, I’ve been shopping around and have found these deals on tech and supplies. I’ve provided links below for you to check them out.</p>
                                <h2 class="fw-bolder mb-4 mt-5">iPad 2021 and Logitech Crayon</h2>
                                <Row>
                                  <Col>
                                    <p class="fs-5 mb-4">Whether it’s taking notes or using pdf textbooks, going paperless can be a great choice for convenience and avoiding expensive printer ink. Almost £400 is a lot of money for a student, but when you factor in the price of stationery, it becomes an easier pill to swallow. If you were planning to buy your own copy of expensive textbooks to annotate, buying an iPad + stylus allows you to annotate digital textbooks, which are much cheaper and sometimes free.</p>
                                    <p class="fs-5 mb-4">A base model iPad from 2021 is more than enough for these use cases, so you can easily save money there. The Logitech Crayon is £35 cheaper than the apple pencil 1st generation (the 2nd generation doesn’t work with this iPad) and charges by sticking out of the iPad’s lightning port. Whereas, the Crayon just charges with a lightning cable.</p>
                                  </Col>
                                  <Col xs={4}>
                                    <Figure>
                                      <Figure.Image
                                        width={171}
                                        height={180}
                                        alt="iPad (2021) Space Grey"
                                        src={ipad}
                                      />
                                      <Figure.Caption>iPad (2021) Space Grey</Figure.Caption>
                                    </Figure>
                                  </Col>
                                </Row>
                                <ButtonGroup vertical>
                                  <Button target="_blank" href="https://www.amazon.co.uk/2021-Apple-iPad-10-2-inch-Wi-Fi/dp/B09G968MFZ/ref=sr_1_1_sspa?crid=1KVFSR3JRTVMV&keywords=ipad+2021&qid=1653288498&sprefix=ipad+2021%2Caps%2C94&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzNlFVSzRFRUVVVDFHJmVuY3J5cHRlZElkPUEwMTUwODQ1M0g2NzI1NTIxSUI5MCZlbmNyeXB0ZWRBZElkPUEwNDM0MTYwMTdKWThHM1lXOEVHQSZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU=">Buy iPad(2021) on Amazon</Button>
                                  <Button target="_blank" href="https://www.amazon.co.uk/Logitech-Bluetooth-Compatible-technology-rejection/dp/B07H3HGVDW/ref=sr_1_1_mod_primary_new?crid=A5QP9YHP56UQ&keywords=logitech%2Bcrayon&qid=1653288524&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sprefix=logitech%2Bcrayon%2Caps%2C104&sr=8-1&th=1">Buy Logitech Crayon on Amazon</Button>
                                  <Button target="_blank" href="https://www.currys.co.uk/products/apple-10.2-ipad-2021-64-gb-silver-10230269.html">Buy iPad(2021) on Currys</Button>
                                  <Button target="_blank" href="https://www.currys.co.uk/products/logitech-crayon-smart-pencil-silver-and-orange-10188631.html">Buy Logitech Crayon on Currys</Button>
                                </ButtonGroup>

                                <h2 class="fw-bolder mb-4 mt-5">Matein Laptop Backpack</h2>
                                <Row>
                                  <Col>
                                  <p class="fs-5 mb-4">Compared to other expensive laptop bags, this backpack is just under £30 and still provides good build quality, 32L of space and multiple organisational compartments. It has padded shoulder straps for comfort and is very durable and can last for years if you take care of it.</p>
                                  </Col>
                                  <Col xs={4}>
                                    <Figure>
                                      <Figure.Image
                                        width={171}
                                        height={180}
                                        alt="MATEIN Travel Laptop Backpack"
                                        src={matein}
                                      />
                                      <Figure.Caption>MATEIN Travel Laptop Backpack</Figure.Caption>
                                    </Figure>
                                  </Col>
                                </Row>
                                <ButtonGroup vertical>
                                  <Button target="_blank" href="https://www.amazon.co.uk/Backpack-Professional-Lightweight-Resistant-Notebook-Grey/dp/B076M85W1K/ref=sr_1_omk_4?crid=S0IU592Z6YVJ&keywords=laptop+backpack&qid=1653288170&sprefix=laptop+backpack%2Caps%2C93&sr=8-4">Buy on Amazon</Button>
                                  <Button target="_blank" href="https://www.ebay.co.uk/p/10026658397">Buy on Ebay</Button>
                                </ButtonGroup>

                                <h2 class="fw-bolder mb-4 mt-5">SanDisk Ultra Flair 128 GB Flash Drive</h2>
                                <Row>
                                  <Col>
                                  <p class="fs-5 mb-4">Having a flash drive is very convenient for transferring files and handing in assignments, and the large capacity means that you can even use it for external storage and backing up work. </p>
                                  </Col>
                                  <Col xs={4}>
                                    <Figure>
                                      <Figure.Image
                                        width={171}
                                        height={180}
                                        alt="SanDisk Ultra Flair 128 GB Flash Drive"
                                        src={sandisk}
                                      />
                                      <Figure.Caption>SanDisk Ultra Flair 128 GB Flash Drive</Figure.Caption>
                                    </Figure>
                                  </Col>
                                </Row>
                                <ButtonGroup vertical>
                                  <Button target="_blank" href="https://www.amazon.co.uk/SanDisk-Ultra-Flair-Flash-Drive/dp/B015CH1PJU/ref=sr_1_2_mod_primary_new?crid=2Z8YHGFH4O50M&keywords=sandisk+ultra+flair&qid=1653288791&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sprefix=sandisk+ultra+flair%2Caps%2C96&sr=8-2">Buy on Amazon</Button>
                                  <Button target="_blank" href="https://www.currys.co.uk/products/sandisk-ultra-flair-usb-3.0-memory-stick-128-gb-silver-10142335.html">Buy on Currys</Button>
                                </ButtonGroup>
                            </section>
                        </article>
                    </div>
                </div>
            </div>
            <Footer/>
          </div>
      )
  }
}

export default BlogPage;
