import React, { Component } from 'react';

import ProductCard from './ProductCard';
import Navigation from './Navigation';
import Footer from './Footer';

class HomePage extends Component {
  render() {
      return (
          <div className="HomePage">
            <Navigation />
            <header class="bg-dark py-5">
                <div class="container px-4 px-lg-5 my-5">
                    <div class="text-center text-white">
                        <h1 class="display-4 fw-bolder">Shop in style</h1>
                        <p class="lead fw-normal text-white-50 mb-0">With this shop homepage template</p>
                    </div>
                </div>
            </header>
            <section class="py-5">
                <div class="container px-4 px-lg-5 mt-5">
                    <div class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
                        <div class="col mb-5">
                            <ProductCard productName="Example Product" productPrice={10} productImage="https://dummyimage.com/450x300/dee2e6/6c757d.jpg" productLink="https://www.amazon.co.uk/" />
                        </div>
                        <div class="col mb-5">
                            <ProductCard productName="Example Product" productPrice={12} productImage="https://dummyimage.com/450x300/dee2e6/6c757d.jpg" productLink="https://www.amazon.co.uk/" />
                        </div>
                        <div class="col mb-5">
                            <ProductCard productName="Example Product" productPrice={47} productImage="https://dummyimage.com/450x300/dee2e6/6c757d.jpg" productLink="https://www.amazon.co.uk/" />
                        </div>
                        <div class="col mb-5">
                            <ProductCard productName="Example Product" productPrice={575} productImage="https://dummyimage.com/450x300/dee2e6/6c757d.jpg" productLink="https://www.amazon.co.uk/" />
                        </div>
                        <div class="col mb-5">
                            <ProductCard productName="Example Product" productPrice={3} productImage="https://dummyimage.com/450x300/dee2e6/6c757d.jpg" productLink="https://www.amazon.co.uk/" />
                        </div>
                        <div class="col mb-5">
                            <ProductCard productName="Example Product" productPrice={79} productImage="https://dummyimage.com/450x300/dee2e6/6c757d.jpg" productLink="https://www.amazon.co.uk/" />
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
          </div>
      )
  }
}

export default HomePage;
